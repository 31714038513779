import React, { useMemo } from 'react'
import {
  Box,
  Slider,
  SliderTrack,
  SliderThumb,
} from "@chakra-ui/react"
import { range } from 'lodash'
import { responsive } from './ThemeProvider/theme'

const DrawSlider = ({ name, value, onChange, step = 1, min, max, labels }) => {
  const ticks = useMemo(() => range(min, max, step), [max, min, step])
  return (
    <Box px="4" width="100%" pb="8">
      <Slider
        onChange={onChange}
        name={name}
        step={step}
        min={min}
        max={max}
        defaultValue={typeof value === 'number' ? value : undefined}
      >
        <SliderTrack bg="white" height={responsive(3, 6)} px={responsive(0.5, 1)}>
          <Box position="relative" height="100%">
            {[0, ...ticks].map((t, i) => (
              <Box
                key={i}
                bg="love.text"
                position="absolute"
                top="0"
                bottom="0"
                width={responsive(1, 2)}
                rounded="full"
                transform="translateX(-50%)"
                style={{ left: `${i / ticks.length * 100}%` }}
              />
            ))}
          </Box>
        </SliderTrack>
        <SliderThumb
          bg="love.primary"
          boxSize={responsive(8, 12)}
          onClick={() => {
            if (!value) onChange(Math.ceil((max - min) / 2) + min)
          }}
        />
      </Slider>
      {labels && (
        <Box position="relative" width="full" color="love.text" fontWeight="700" fontSize={responsive('1em', '1.25em')}>
          <Box fontSize="1.5em" transform="translateX(-50%)" position="absolute" left="0" top="0">{labels[0]}</Box>
          <Box fontSize="1.5em" transform="translateX(50%)" position="absolute" right="0" top="0">{labels[1]}</Box>
        </Box>
      )}
    </Box>
  )
}

export default DrawSlider
