import { Box, Flex, Heading, Stack, VStack } from '@chakra-ui/layout'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFormContext, useFormState } from "react-hook-form";
// import { Progress } from '@chakra-ui/progress';
import { navigate } from 'gatsby';
import { last } from 'lodash';
import { useLocation } from 'react-use';
import { Helmet } from 'react-helmet';

import loverbg from './loverbg.png';
import friendBg from './friendbg.png';

import FullpageHeight from '../../components/FullpageHeight'
import Text from '../../components/Text'
// import BackgroundImage from '../../components/BackgroundImage'
import MultiChoice from '../../components/MultiChoice'
import QuestionButton from '../../components/QuestionButton'
// import WhiteDrawBox from '../../components/WhiteDrawBox'
// import DrawArrowButton from '../../components/DrawArrowButton';
import Progress from '../../components/Progress';
import DrawSlider from '../../components/DrawSlider';
import DrawButton from '../../components/DrawButton';
import useQuestions from './useQuestions';
import { responsive } from '../../components/ThemeProvider/theme';

const ManagedQuestion = ({ qId, id, count, nextPath, isLast }) => {
  const { questions, polorQuestions } = useQuestions(id)
  const { watch, control, setValue } = useFormContext();
  const { isSubmitting } = useFormState({ control })
  const startTime = useMemo(() => new Date(), [qId])
  const polorId = qId - 21
  const isPolor = polorId > -1
  const isLove = id === 'lover'
  const polorAnswered = Boolean(watch(`polor.${polorId}`))
  const [thisPolarAnswered, setThisPolorAnswered] = useState()
  useEffect(() => {
    if (!questions) {
      navigate('/q/love-languages/')
    }
  }, [questions])
  useEffect(() => {
    if (isSubmitting) {
      setThisPolorAnswered(true)
      setTimeout(() => {
        if (isLast) {
          navigate(nextPath)
        } else {
          window.history.pushState(null, null, nextPath)
          setThisPolorAnswered(false)
        }
      }, 500)
    }
  }, [isSubmitting])
  if (!questions) return null
  const q = questions[qId - 1]
  const progress = (qId - 1) / count * 100
  return (
    <Flex
      backgroundImage={`url(${isLove ? loverbg : friendBg})`}
      backgroundRepeat="repeat"
      backgroundSize={responsive('75%', '30%')}
      backgroundPosition="50% 50%"
      flex="1"
      flexDirection="column"
    >
      <FullpageHeight maxWidth="container.sm" px={responsive(4, 16)} height="100%">
        <Box position="relative">
          <Box position="absolute" left="0" right="0" top="2em" textAlign="right">
            <Progress color="love.title" progress={progress} />
          </Box>
        </Box>
        <VStack pt="6" pb="2" spacing="6" px="2" flex="1" justifyContent="center">
          <Stack>
            <Heading textAlign="left" color="love.primary" fontWeight="black" fontSize={responsive('1.75em', '2em')} fontFamily="dokdo" mt="0.5em">
              <Text.Inline fontSize="1.5em" color="love.title">{qId}</Text.Inline><Text.Inline color="love.qNumber">/{count}</Text.Inline>
            </Heading>
            <Box>
              <Text letterSpacing="0.125rem" fontWeight="bold" whiteSpace="pre-wrap" color="love.text" fontSize="1.25em">
                {isPolor ? polorQuestions[polorId]?.title : '下面兩個描述，\n哪一個對你來說更有意義？'}
              </Text>
            </Box>
          </Stack>
          <Controller
            key={qId}
            name={isPolor ? `polor.${polorId}` : `a.${qId - 1}`}
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => isPolor ? (
              <DrawSlider
                step={1}
                min={1}
                max={7}
                labels={['1', '7']}
                {...field}
                value={field.value && +last(field.value.split('.'))}
                onChange={(v) => {
                  field.onChange(`${polorQuestions[polorId]?.type}.${polorQuestions[polorId]?.index}.${v}`)
                  // setAnswered(true)
                  setValue(`polorT.${polorId}`, new Date() - startTime)
                }}
              />
            ) : (
              <MultiChoice
                spacing="1.25em"
                options={q}
                optionEle={p => <QuestionButton fontWeight="700" {...p} />}
                autoSubmit
                {...field}
                onChange={(v) => {
                  field.onChange(v)
                  setValue(`ax.${qId - 1}`, q.find(o => o.value !== v)?.value)
                  setValue(`t.${qId - 1}`, new Date() - startTime)
                }}
              />
            )}
          />
          {isPolor && (
            <Box width={responsive('full', '360px')} pt="4" mx="auto" textAlign="center">
              <DrawButton
                isDisabled={!polorAnswered || isSubmitting || thisPolarAnswered}
                bg="love.primary"
                type="submit"
              >送出</DrawButton>
            </Box>
          )}
        </VStack>
      {/* <Box position="absolute" left="0" bottom="1em">
        <DrawArrowButton color="love.primary" onClick={() => window.history.back()}>上一題</DrawArrowButton>
      </Box> */}
      </FullpageHeight>
    </Flex>
  )
}

const Question = ({ pageContext }) => {
  const init = useRef()
  const { id, count, basename } = pageContext
  const location = useLocation()
  const qid = useMemo(() => {
    const res = new RegExp(`${basename}/(\\d+)`).exec(location.pathname)
    return res ? res[1] : 1
  }, [basename, location.pathname])
  useEffect(() => {
    if (init.current) {
      const pagePath = location
        ? location.pathname + location.search + location.hash
        : undefined
      if (typeof window.gtag === 'function') window.gtag(`event`, `page_view`, { page_path: pagePath })
    }
    init.current = true
  }, [location.pathname])
  return (
    <>
      <Helmet>
        <title>{pageContext.title.replace(/\d+/, qid)}</title>
      </Helmet>
      <ManagedQuestion
        nextPath={`${basename}/${qid < count ? +qid + 1 : 'calculate'}/`}
        isLast={qid == count}
        count={count}
        qId={qid}
        id={id}
      />
    </>
  )
}

export default Question
