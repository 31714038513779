import { mapValues } from 'lodash'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSessionStorage } from 'react-use'

const defaultName = '愛人'

const useQuestions = (id) => {
  const { watch } = useFormContext()
  const relation = watch('relation')

  const [iter] = useSessionStorage(`superquiz:love-languages:iter`)

  const [storedQuestions] = useSessionStorage(`superquiz:love-languages:${id}:${iter}`)
  const questions = useMemo(() => storedQuestions?.[0].map(q => q.map(opt => mapValues(opt, v => v.replace(/{{relation}}/, relation || defaultName)))), [relation])
  const polorQuestions = useMemo(() => storedQuestions?.[1].map(q => ({
    ...q,
    title: q.title.replace(/{{relation}}/, relation || defaultName),
  })), [relation])
  return {
    questions,
    polorQuestions,
  }
}

export default useQuestions
